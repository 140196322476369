<template>
  <div class="img-wrap">
    <div class="img-content" v-for="(item, index) in imgList" :key="index">
      <el-image
        style="width: 100px; height: 100px; margin: 20px"
        :src="item"
        :preview-src-list="imgList"
      >
      </el-image>
      <span>判断结果：{{isStandard(item)? '异常': '合格'}}</span>
    </div>
      <video
      v-for="(item) in videoList"
      :key="item.id"
      style="width: 200px; height: 100px;margin-right:20px"
      :src="item"
      :controls="controls"
    >
    </video>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Array,
      default: () => [],
    },
    checkPicList: Array,
  },
  watch: {
    value(newVal, oldVal) {
      const tempArr = [];
      const videoArr = [];
      const photoVoList = newVal.filter((f) => f.fileUrl.indexOf('mp4') === -1);
      const videoVoList = newVal.filter((f) => f.fileUrl.indexOf('mp4') !== -1);
      // for (const item of newVal) {
      //   tempArr.push(item.fileUrl);
      // }
      for (const item of photoVoList) {
        tempArr.push(item.fileUrl);
      }
      for (const item of videoVoList) {
        videoArr.push(item.fileUrl);
      }
      this.imgList = tempArr;
      this.videoList = videoArr;
    },
    checkPicList(val) {
      console.log(val);
      this.checkImageList = val;
    },
  },
  computed: {
    isStandard() {
      return (pic) => this.checkImageList.some((p) => p.fileUrl === pic);
    },
  },
  data() {
    return {
      imgList: [],
      videoList: [],
      controls: true,
      checkImageList: [],
    };
  },
};
</script>

<style scoped>
.img-wrap{
    display: flex;
    align-items: center;
    flex-flow: wrap;
    margin-bottom: 20px;
}
.img-content{
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}
</style>
