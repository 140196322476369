import Form, { formCreate } from '@/found/components/form';
import request from '@/found/utils/request';
import ProductRespVos from './product_list.vue';
import PhotoRespVos from './photo_list.vue';

formCreate.component('ProductRespVos', ProductRespVos);
formCreate.component('PhotoRespVos', PhotoRespVos);
export default {
  name: 'table-component',
  extends: Form,
  components: {
    formCreate: formCreate.$form(),
  },
  data() {
    return { formFunctionCode: 'banquet_present_list_form' };
  },
  created() {
  },
  methods: {
    formComplete() {
      if (this.formConfig.code === 'view') {
        const tempData = this.formConfig.row;
        this.setValue(tempData);
        if (tempData && tempData.photoRespVos && tempData.photoRespVos.length !== 0) {
          this.hiddenFields(false, ['photoRespVos', 'title1']);
        } else {
          this.hiddenFields(true, ['photoRespVos', 'title1']);
        }
        if (tempData && tempData.productRespVos && tempData.productRespVos.length !== 0) {
          this.hiddenFields(false, ['productRespVos', 'title2']);
        } else {
          this.hiddenFields(true, ['productRespVos', 'title2']);
        }
      }
    },
    // 校验图片
    checkPhoto(pics) {
      request.post('/sfa/sensegalaxyfilestatus/queryFileStatus', pics).then((res) => {
        console.log(res);
        if (res.success) {
          this.fApi.getRule('photoRespVos').props.checkPicList = res.result || [];
        }
      });
    },
    setRule(item) {
      const v = item;
      if (v.field === 'productRespVos') {
        v.props.value = this.formConfig.row.productRespVos || [];
      }
      if (v.field === 'photoRespVos') {
        const photoList = this.formConfig.row.photoRespVos || [];
        this.checkPhoto(photoList.map((pics) => pics.fileUrl));
        v.props.value = photoList;
        v.props.checkPicList = [];
      }
      return v;
    },
  },

};
